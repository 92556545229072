import { combineReducers } from "redux";
import {
  authorization,
  profile,
  currentRoute,
  notifications,
  app,
  usersForm,
  users,
  alerts,
  alertsForm,
  currentTheme,
  vehicles,
  vehiclesForm,
  customers,
  customersForm,
  roads,
  roadsForm,
  roatSheets,
  roatSheetsForm,
  expenses,
  expensesForm,
  epis,
  episForm,
  epiRequests,
  epiRequestsForm,
  absences,
  absencesForm,
  permisions,
  permisionsForm,
  shifts,
  shiftsForm,
  equipments,
  equipmentsForm,
  singUps,
  singUpsForm,
  petitions,
  petitionsForm,
  holidays,
  holidaysForm,
  maintenances,
  maintenancesForm,
  workOrders,
  workOrdersForm,
  events,
  eventsForm,
  articles,
  articlesForm,
  orders,
  ordersForm,
  configurations,
  configurationsForm,
  departments,
  departmentsForm,
  requests,
  requestsForm,
  advertisements,
  advertisementsForm,
  roles,
  rolesForm,
  tpvs,
  tpvsForm,
  tpvRequests,
  tpvRequestsForm,
  centers,
  centersForm,
  refuelings,
  refuelingsForm,
} from "./Reducers";

export default combineReducers({
  refuelings,
  refuelingsForm,
  centers,
  centersForm,
  tpvRequests,
  tpvRequestsForm,
  tpvs,
  tpvsForm,
  roles,
  rolesForm,
  advertisements,
  advertisementsForm,
  requests,
  requestsForm,
  departments,
  departmentsForm,
  configurations,
  configurationsForm,
  orders,
  ordersForm,
  articles,
  articlesForm,
  events,
  eventsForm,
  workOrders,
  workOrdersForm,
  maintenances,
  maintenancesForm,
  holidays,
  holidaysForm,
  petitions,
  petitionsForm,
  singUps,
  singUpsForm,
  equipments,
  equipmentsForm,
  shifts,
  shiftsForm,
  permisions,
  permisionsForm,
  absences,
  absencesForm,
  epiRequests,
  epiRequestsForm,
  epis,
  episForm,
  expenses,
  expensesForm,
  roatSheets,
  roatSheetsForm,
  roads,
  roadsForm,
  customers,
  customersForm,
  vehicles,
  vehiclesForm,
  app,
  authorization,
  currentRoute,
  profile,
  notifications,
  usersForm,
  users,
  alerts,
  alertsForm,
  currentTheme,
});
