import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
    ADD_NEW_VEHICLE,
    DELETE_VEHICLE,
    VEHICLE_FORM_TOOGLE_LOADING,
    SET_VEHICLE_LIST,
    SHOW_NOTIFICATION,
    UPDATE_VEHICLE,
} from "../constants";
import { formatVehicles } from "./settingsActionsUtils";

/* VEHICLE LIST */
export const fetchVehicles = () => {
    return async (dispatch) => {
        const response = await axios
            .get(API.vehicles.list)
            .then((response) => {
                const vehicles = formatVehicles(response.data);
                dispatch({
                    type: SET_VEHICLE_LIST,
                    payload: keyBy(vehicles, "_id"),
                });
                return response.status;
            })
            .catch((err) => err);
        return response;
    };
};

// VEHICLES
export const createVehicle = (newVehicle) => {
    return async (dispatch) => {
        dispatch({ type: VEHICLE_FORM_TOOGLE_LOADING });
        const response = await axios
            .post(API.vehicles.create, newVehicle)
            .then((response) => {
                const vehicle = formatVehicles(response.data);
                dispatch({ type: ADD_NEW_VEHICLE, payload: vehicle });
                dispatch({ type: VEHICLE_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "success",
                        message: "Vehículo creado con éxito.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: VEHICLE_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const updateVehicle = (updatedVehicle) => {
    return async (dispatch) => {
        dispatch({ type: VEHICLE_FORM_TOOGLE_LOADING });
        const response = await axios
            .put(`${API.vehicles.edit}${updatedVehicle && updatedVehicle._id}`, updatedVehicle)
            .then((response) => {
                const vehicle = formatVehicles(response.data);
                dispatch({ type: UPDATE_VEHICLE, payload: vehicle });
                dispatch({ type: VEHICLE_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Vehículo actualizado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: VEHICLE_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};


export const fetchVehicle = (_id) => {
    return async (dispatch) => {
        dispatch({ type: VEHICLE_FORM_TOOGLE_LOADING });
        const response = await axios
            .get(`${API.vehicles.edit}${_id}`)
            .then((response) => {
                const vehicle = formatVehicles(response.data);
                dispatch({ type: UPDATE_VEHICLE, payload: vehicle });
                dispatch({ type: VEHICLE_FORM_TOOGLE_LOADING });
                return vehicle;
            })
            .catch((err) => {
                dispatch({ type: VEHICLE_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const deleteVehicles = (Ids) => {
    return async (dispatch) => {
        dispatch({ type: VEHICLE_FORM_TOOGLE_LOADING });
        const response = await axios
            .delete(`${API.vehicles.delete}`, { data: Ids })
            .then((response) => {
                dispatch({ type: DELETE_VEHICLE, payload: Ids });
                dispatch({ type: VEHICLE_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Vehículo eliminado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: VEHICLE_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};
