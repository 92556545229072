import moment from "moment";
import { createCrudActions } from '../utils/crudGenerator';

const formatRefuelings = (data) => {
    if (!data) {
        return [];
    }
    const formatItem = (item) => ({
        ...item,
        label: `${item.name}`,
        value: item._id,
        start: item.start && moment(item.start),
    });

    if (Array.isArray(data)) {
        return data.map(formatItem);
    }
    return formatItem(data);
};

export const {
    fetch: fetchRefuelings,
    create: createRefueling,
    update: updateRefueling,
    delete: deleteRefuelings
} = createCrudActions('REFUELING', "Repostaje", formatRefuelings);
