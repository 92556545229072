import { handleActions, handleAction } from "redux-actions";
import {
    AUTH,
    SET_PROFILE,
    SET_CURRENT_ROUTE,
    LOGOUT,
    SHOW_NOTIFICATION,
    CLOSE_NOTIFICATION,
    SET_SIDE_MENU_STATE,
    OPEN_USERS_FORM,
    CLOSE_USERS_FORM,
    EDIT_SELECTED_USER,
    USERS_FORM_TOOGLE_LOADING,
    SET_USER_LIST,
    ADD_NEW_USER,
    UPDATE_USER,
    DELETE_USER,
    OPEN_ALERT_FORM,
    CLOSE_ALERT_FORM,
    EDIT_SELECTED_ALERT,
    ALERT_FORM_TOOGLE_LOADING,
    SET_ALERT_LIST,
    ADD_NEW_ALERT,
    UPDATE_ALERT,
    DELETE_ALERT,
    THEME,
    ADD_NEW_VEHICLE,
    SET_VEHICLE_LIST,
    UPDATE_VEHICLE,
    DELETE_VEHICLE,
    OPEN_VEHICLE_FORM,
    CLOSE_VEHICLE_FORM,
    EDIT_SELECTED_VEHICLE,
    VEHICLE_FORM_TOOGLE_LOADING,
    ADD_NEW_CUSTOMER,
    SET_CUSTOMER_LIST,
    UPDATE_CUSTOMER,
    DELETE_CUSTOMER,
    OPEN_CUSTOMER_FORM,
    CLOSE_CUSTOMER_FORM,
    EDIT_SELECTED_CUSTOMER,
    CUSTOMER_FORM_TOOGLE_LOADING,
    ADD_NEW_ROAD,
    SET_ROAD_LIST,
    UPDATE_ROAD,
    DELETE_ROAD,
    OPEN_ROAD_FORM,
    CLOSE_ROAD_FORM,
    EDIT_SELECTED_ROAD,
    ROAD_FORM_TOOGLE_LOADING,
    ADD_NEW_ROATSHEET,
    SET_ROATSHEET_LIST,
    UPDATE_ROATSHEET,
    DELETE_ROATSHEET,
    OPEN_ROATSHEET_FORM,
    CLOSE_ROATSHEET_FORM,
    EDIT_SELECTED_ROATSHEET,
    ROATSHEET_FORM_TOOGLE_LOADING,
    ADD_NEW_EXPENSE,
    SET_EXPENSE_LIST,
    UPDATE_EXPENSE,
    DELETE_EXPENSE,
    OPEN_EXPENSE_FORM,
    CLOSE_EXPENSE_FORM,
    EDIT_SELECTED_EXPENSE,
    EXPENSE_FORM_TOOGLE_LOADING,
    ADD_NEW_EPI,
    SET_EPI_LIST,
    UPDATE_EPI,
    DELETE_EPI,
    OPEN_EPI_FORM,
    CLOSE_EPI_FORM,
    EDIT_SELECTED_EPI,
    EPI_FORM_TOOGLE_LOADING,
    ADD_NEW_EPIREQUEST,
    SET_EPIREQUEST_LIST,
    UPDATE_EPIREQUEST,
    DELETE_EPIREQUEST,
    OPEN_EPIREQUEST_FORM,
    CLOSE_EPIREQUEST_FORM,
    EDIT_SELECTED_EPIREQUEST,
    EPIREQUEST_FORM_TOOGLE_LOADING,
    ADD_NEW_ABSENCE,
    SET_ABSENCE_LIST,
    UPDATE_ABSENCE,
    DELETE_ABSENCE,
    OPEN_ABSENCE_FORM,
    CLOSE_ABSENCE_FORM,
    EDIT_SELECTED_ABSENCE,
    ABSENCE_FORM_TOOGLE_LOADING,
    ADD_NEW_PERMISION,
    SET_PERMISION_LIST,
    UPDATE_PERMISION,
    DELETE_PERMISION,
    OPEN_PERMISION_FORM,
    CLOSE_PERMISION_FORM,
    EDIT_SELECTED_PERMISION,
    PERMISION_FORM_TOOGLE_LOADING,
    ADD_NEW_SHIFT,
    SET_SHIFT_LIST,
    UPDATE_SHIFT,
    DELETE_SHIFT,
    OPEN_SHIFT_FORM,
    CLOSE_SHIFT_FORM,
    EDIT_SELECTED_SHIFT,
    SHIFT_FORM_TOOGLE_LOADING,
    ADD_NEW_EQUIPMENT,
    SET_EQUIPMENT_LIST,
    UPDATE_EQUIPMENT,
    DELETE_EQUIPMENT,
    OPEN_EQUIPMENT_FORM,
    CLOSE_EQUIPMENT_FORM,
    EDIT_SELECTED_EQUIPMENT,
    EQUIPMENT_FORM_TOOGLE_LOADING,
    ADD_NEW_SINGUP,
    SET_SINGUP_LIST,
    UPDATE_SINGUP,
    DELETE_SINGUP,
    OPEN_SINGUP_FORM,
    CLOSE_SINGUP_FORM,
    EDIT_SELECTED_SINGUP,
    SINGUP_FORM_TOOGLE_LOADING,
    ADD_NEW_PETITION,
    SET_PETITION_LIST,
    UPDATE_PETITION,
    DELETE_PETITION,
    OPEN_PETITION_FORM,
    CLOSE_PETITION_FORM,
    EDIT_SELECTED_PETITION,
    PETITION_FORM_TOOGLE_LOADING,
    ADD_NEW_HOLIDAY,
    SET_HOLIDAY_LIST,
    UPDATE_HOLIDAY,
    DELETE_HOLIDAY,
    OPEN_HOLIDAY_FORM,
    CLOSE_HOLIDAY_FORM,
    EDIT_SELECTED_HOLIDAY,
    HOLIDAY_FORM_TOOGLE_LOADING,
    ADD_NEW_MAINTENANCE,
    SET_MAINTENANCE_LIST,
    UPDATE_MAINTENANCE,
    DELETE_MAINTENANCE,
    OPEN_MAINTENANCE_FORM,
    CLOSE_MAINTENANCE_FORM,
    EDIT_SELECTED_MAINTENANCE,
    MAINTENANCE_FORM_TOOGLE_LOADING,
    ADD_NEW_WORKORDER,
    SET_WORKORDER_LIST,
    UPDATE_WORKORDER,
    DELETE_WORKORDER,
    OPEN_WORKORDER_FORM,
    CLOSE_WORKORDER_FORM,
    EDIT_SELECTED_WORKORDER,
    WORKORDER_FORM_TOOGLE_LOADING,
    ADD_NEW_EVENT,
    SET_EVENT_LIST,
    UPDATE_EVENT,
    DELETE_EVENT,
    OPEN_EVENT_FORM,
    CLOSE_EVENT_FORM,
    EDIT_SELECTED_EVENT,
    EVENT_FORM_TOOGLE_LOADING,
    ADD_NEW_ARTICLE,
    SET_ARTICLE_LIST,
    UPDATE_ARTICLE,
    DELETE_ARTICLE,
    OPEN_ARTICLE_FORM,
    CLOSE_ARTICLE_FORM,
    EDIT_SELECTED_ARTICLE,
    ARTICLE_FORM_TOOGLE_LOADING,
    ADD_NEW_ORDER,
    SET_ORDER_LIST,
    UPDATE_ORDER,
    DELETE_ORDER,
    OPEN_ORDER_FORM,
    CLOSE_ORDER_FORM,
    EDIT_SELECTED_ORDER,
    ORDER_FORM_TOOGLE_LOADING,
    ADD_NEW_CONFIGURATION,
    SET_CONFIGURATION_LIST,
    UPDATE_CONFIGURATION,
    DELETE_CONFIGURATION,
    OPEN_CONFIGURATION_FORM,
    CLOSE_CONFIGURATION_FORM,
    EDIT_SELECTED_CONFIGURATION,
    CONFIGURATION_FORM_TOOGLE_LOADING,
    ADD_NEW_DEPARTMENT,
    SET_DEPARTMENT_LIST,
    UPDATE_DEPARTMENT,
    DELETE_DEPARTMENT,
    OPEN_DEPARTMENT_FORM,
    CLOSE_DEPARTMENT_FORM,
    EDIT_SELECTED_DEPARTMENT,
    DEPARTMENT_FORM_TOOGLE_LOADING,
    ADD_NEW_REQUEST,
    SET_REQUEST_LIST,
    UPDATE_REQUEST,
    DELETE_REQUEST,
    OPEN_REQUEST_FORM,
    CLOSE_REQUEST_FORM,
    EDIT_SELECTED_REQUEST,
    REQUEST_FORM_TOOGLE_LOADING,
    ADD_NEW_ADVERTISEMENT,
    SET_ADVERTISEMENT_LIST,
    UPDATE_ADVERTISEMENT,
    DELETE_ADVERTISEMENT,
    OPEN_ADVERTISEMENT_FORM,
    CLOSE_ADVERTISEMENT_FORM,
    EDIT_SELECTED_ADVERTISEMENT,
    ADVERTISEMENT_FORM_TOOGLE_LOADING,
    ADD_NEW_ROLE,
    SET_ROLE_LIST,
    UPDATE_ROLE,
    DELETE_ROLE,
    OPEN_ROLE_FORM,
    CLOSE_ROLE_FORM,
    EDIT_SELECTED_ROLE,
    ROLE_FORM_TOOGLE_LOADING,
    ADD_NEW_TPV,
    SET_TPV_LIST,
    UPDATE_TPV,
    DELETE_TPV,
    OPEN_TPV_FORM,
    CLOSE_TPV_FORM,
    EDIT_SELECTED_TPV,
    TPV_FORM_TOOGLE_LOADING,
    ADD_NEW_TPVREQUEST,
    SET_TPVREQUEST_LIST,
    UPDATE_TPVREQUEST,
    DELETE_TPVREQUEST,
    OPEN_TPVREQUEST_FORM,
    CLOSE_TPVREQUEST_FORM,
    EDIT_SELECTED_TPVREQUEST,
    TPVREQUEST_FORM_TOOGLE_LOADING,
    ADD_NEW_CENTER,
    SET_CENTER_LIST,
    UPDATE_CENTER,
    DELETE_CENTER,
    OPEN_CENTER_FORM,
    CLOSE_CENTER_FORM,
    EDIT_SELECTED_CENTER,
    CENTER_FORM_TOOGLE_LOADING,
} from "./../../constants";
import { createCrudReducers } from "../../utils/crudGenerator";

/* BASIC */
export const authorization = handleActions(
    {
        [AUTH]: (state, action) => action.payload,
        [LOGOUT]: () => ({ auth: false }),
    },
    null
);

/* PROFILE */
export const profile = handleActions(
    {
        [SET_PROFILE]: (state, action) => action.payload,
    },
    null
);

/* APP */
export const currentRoute = handleAction(
    SET_CURRENT_ROUTE,
    (state, action) => action.payload,
    {}
);

export const notifications = handleActions(
    {
        [SHOW_NOTIFICATION]: (state, action) => action.payload,
        [CLOSE_NOTIFICATION]: (state, action) => ({
            show: false,
            status: null,
            message: null,
        }),
    },
    { show: false, status: null, message: null }
);

/* APP */
export const app = handleActions(
    {
        [SET_SIDE_MENU_STATE]: (state, action) => {
            return { ...state, isCollapsedSideMenu: action.payload };
        },
    },
    { isCollapsedSideMenu: true }
);

export const currentTheme = handleAction(
    THEME,
    (state, action) => action.payload,
    false
);

export const users = handleActions(
    {
        [SET_USER_LIST]: (state, action) => action.payload,
        [ADD_NEW_USER]: (state, action) => {
            const newState = {
                ...state,
                [action.payload.value]: action.payload,
            };
            return newState;
        },
        [UPDATE_USER]: (state, action) => {
            const newState = {
                ...state,
                [action.payload.value]: action.payload,
            };
            return newState;
        },
        [DELETE_USER]: (state, action) => {
            action.payload.forEach((number) => {
                delete state[number];
            });
            return { ...state };
        },
    },
    null
);

export const usersForm = handleActions(
    {
        [OPEN_USERS_FORM]: (state) => ({
            show: !state.show,
            data: null,
            loading: false,
        }),
        [CLOSE_USERS_FORM]: () => ({ show: false, data: null, loading: false }),
        [EDIT_SELECTED_USER]: (s, action) => ({
            show: true,
            data: action.payload,
            loading: false,
        }),
        [USERS_FORM_TOOGLE_LOADING]: (state) => ({
            ...state,
            loading: !state.loading,
        }),
    },
    { show: false, data: null, loading: false }
);

export const alerts = handleActions(
    {
        [SET_ALERT_LIST]: (state, action) => action.payload,
        [ADD_NEW_ALERT]: (state, action) => {
            const newState = {
                ...state,
                [action.payload.value]: action.payload,
            };
            return newState;
        },
        [UPDATE_ALERT]: (state, action) => {
            const newState = {
                ...state,
                [action.payload.value]: action.payload,
            };
            return newState;
        },
        [DELETE_ALERT]: (state, action) => {
            action.payload.forEach((number) => {
                delete state[number];
            });
            return { ...state };
        },
    },
    null
);

export const alertsForm = handleActions(
    {
        [OPEN_ALERT_FORM]: (state) => ({
            show: !state.show,
            data: null,
            loading: false,
        }),
        [CLOSE_ALERT_FORM]: () => ({ show: false, data: null, loading: false }),
        [EDIT_SELECTED_ALERT]: (s, action) => ({
            show: true,
            data: action.payload,
            loading: false,
        }),
        [ALERT_FORM_TOOGLE_LOADING]: (state) => ({
            ...state,
            loading: !state.loading,
        }),
    },
    { show: false, data: null, loading: false }
);

// VEHICLES
export const vehicles = handleActions(
    {
        [SET_VEHICLE_LIST]: (state, action) => action.payload,
        [ADD_NEW_VEHICLE]: (state, action) => {
            const newState = {
                ...state,
                [action.payload.value]: action.payload,
            };
            return newState;
        },
        [UPDATE_VEHICLE]: (state, action) => {
            const newState = {
                ...state,
                [action.payload.value]: action.payload,
            };
            return newState;
        },
        [DELETE_VEHICLE]: (state, action) => {
            action.payload.forEach((number) => {
                delete state[number];
            });
            return { ...state };
        },
    },
    null
);

export const vehiclesForm = handleActions(
    {
        [OPEN_VEHICLE_FORM]: (state) => ({
            show: !state.show,
            data: null,
            loading: false,
        }),
        [CLOSE_VEHICLE_FORM]: () => ({
            show: false,
            data: null,
            loading: false,
        }),
        [EDIT_SELECTED_VEHICLE]: (s, action) => ({
            show: true,
            data: action.payload,
            loading: false,
        }),
        [VEHICLE_FORM_TOOGLE_LOADING]: (state) => ({
            ...state,
            loading: !state.loading,
        }),
    },
    { show: false, data: null, loading: false }
);

// CUSTOMERS
export const customers = handleActions(
    {
        [SET_CUSTOMER_LIST]: (state, action) => action.payload,
        [ADD_NEW_CUSTOMER]: (state, action) => {
            const newState = {
                ...state,
                [action.payload.value]: action.payload,
            };
            return newState;
        },
        [UPDATE_CUSTOMER]: (state, action) => {
            const newState = {
                ...state,
                [action.payload.value]: action.payload,
            };
            return newState;
        },
        [DELETE_CUSTOMER]: (state, action) => {
            action.payload.forEach((number) => {
                delete state[number];
            });
            return { ...state };
        },
    },
    null
);

export const customersForm = handleActions(
    {
        [OPEN_CUSTOMER_FORM]: (state) => ({
            show: !state.show,
            data: null,
            loading: false,
        }),
        [CLOSE_CUSTOMER_FORM]: () => ({
            show: false,
            data: null,
            loading: false,
        }),
        [EDIT_SELECTED_CUSTOMER]: (s, action) => ({
            show: true,
            data: action.payload,
            loading: false,
        }),
        [CUSTOMER_FORM_TOOGLE_LOADING]: (state) => ({
            ...state,
            loading: !state.loading,
        }),
    },
    { show: false, data: null, loading: false }
);

// ROADS
export const roads = handleActions(
    {
        [SET_ROAD_LIST]: (state, action) => action.payload,
        [ADD_NEW_ROAD]: (state, action) => {
            const newState = {
                ...state,
                [action.payload.value]: action.payload,
            };
            return newState;
        },
        [UPDATE_ROAD]: (state, action) => {
            const newState = {
                ...state,
                [action.payload.value]: action.payload,
            };
            return newState;
        },
        [DELETE_ROAD]: (state, action) => {
            action.payload.forEach((number) => {
                delete state[number];
            });
            return { ...state };
        },
    },
    null
);

export const roadsForm = handleActions(
    {
        [OPEN_ROAD_FORM]: (state) => ({
            show: !state.show,
            data: null,
            loading: false,
        }),
        [CLOSE_ROAD_FORM]: () => ({
            show: false,
            data: null,
            loading: false,
        }),
        [EDIT_SELECTED_ROAD]: (s, action) => ({
            show: true,
            data: action.payload,
            loading: false,
        }),
        [ROAD_FORM_TOOGLE_LOADING]: (state) => ({
            ...state,
            loading: !state.loading,
        }),
    },
    { show: false, data: null, loading: false }
);

// ROATSHEETS
export const roatSheets = handleActions(
    {
        [SET_ROATSHEET_LIST]: (state, action) => action.payload,
        [ADD_NEW_ROATSHEET]: (state, action) => {
            const newState = {
                ...state,
                [action.payload.value]: action.payload,
            };
            return newState;
        },
        [UPDATE_ROATSHEET]: (state, action) => {
            const newState = {
                ...state,
                [action.payload.value]: action.payload,
            };
            return newState;
        },
        [DELETE_ROATSHEET]: (state, action) => {
            action.payload.forEach((number) => {
                delete state[number];
            });
            return { ...state };
        },
    },
    null
);

export const roatSheetsForm = handleActions(
    {
        [OPEN_ROATSHEET_FORM]: (state) => ({
            show: !state.show,
            data: null,
            loading: false,
        }),
        [CLOSE_ROATSHEET_FORM]: () => ({
            show: false,
            data: null,
            loading: false,
        }),
        [EDIT_SELECTED_ROATSHEET]: (s, action) => ({
            show: true,
            data: action.payload,
            loading: false,
        }),
        [ROATSHEET_FORM_TOOGLE_LOADING]: (state) => ({
            ...state,
            loading: !state.loading,
        }),
    },
    { show: false, data: null, loading: false }
);

// EXPENSES
export const expenses = handleActions(
    {
        [SET_EXPENSE_LIST]: (state, action) => action.payload,
        [ADD_NEW_EXPENSE]: (state, action) => {
            const newState = {
                ...state,
                [action.payload.value]: action.payload,
            };
            return newState;
        },
        [UPDATE_EXPENSE]: (state, action) => {
            const newState = {
                ...state,
                [action.payload.value]: action.payload,
            };
            return newState;
        },
        [DELETE_EXPENSE]: (state, action) => {
            action.payload.forEach((number) => {
                delete state[number];
            });
            return { ...state };
        },
    },
    null
);

export const expensesForm = handleActions(
    {
        [OPEN_EXPENSE_FORM]: (state) => ({
            show: !state.show,
            data: null,
            loading: false,
        }),
        [CLOSE_EXPENSE_FORM]: () => ({
            show: false,
            data: null,
            loading: false,
        }),
        [EDIT_SELECTED_EXPENSE]: (s, action) => ({
            show: true,
            data: action.payload,
            loading: false,
        }),
        [EXPENSE_FORM_TOOGLE_LOADING]: (state) => ({
            ...state,
            loading: !state.loading,
        }),
    },
    { show: false, data: null, loading: false }
);

// EPIS
export const epis = handleActions(
    {
        [SET_EPI_LIST]: (state, action) => action.payload,
        [ADD_NEW_EPI]: (state, action) => {
            const newState = {
                ...state,
                [action.payload.value]: action.payload,
            };
            return newState;
        },
        [UPDATE_EPI]: (state, action) => {
            const newState = {
                ...state,
                [action.payload.value]: action.payload,
            };
            return newState;
        },
        [DELETE_EPI]: (state, action) => {
            action.payload.forEach((number) => {
                delete state[number];
            });
            return { ...state };
        },
    },
    null
);

export const episForm = handleActions(
    {
        [OPEN_EPI_FORM]: (state) => ({
            show: !state.show,
            data: null,
            loading: false,
        }),
        [CLOSE_EPI_FORM]: () => ({
            show: false,
            data: null,
            loading: false,
        }),
        [EDIT_SELECTED_EPI]: (s, action) => ({
            show: true,
            data: action.payload,
            loading: false,
        }),
        [EPI_FORM_TOOGLE_LOADING]: (state) => ({
            ...state,
            loading: !state.loading,
        }),
    },
    { show: false, data: null, loading: false }
);

// EPIREQUESTS
export const epiRequests = handleActions(
    {
        [SET_EPIREQUEST_LIST]: (state, action) => action.payload,
        [ADD_NEW_EPIREQUEST]: (state, action) => {
            const newState = {
                ...state,
                [action.payload.value]: action.payload,
            };
            return newState;
        },
        [UPDATE_EPIREQUEST]: (state, action) => {
            const newState = {
                ...state,
                [action.payload.value]: action.payload,
            };
            return newState;
        },
        [DELETE_EPIREQUEST]: (state, action) => {
            action.payload.forEach((number) => {
                delete state[number];
            });
            return { ...state };
        },
    },
    null
);

export const epiRequestsForm = handleActions(
    {
        [OPEN_EPIREQUEST_FORM]: (state) => ({
            show: !state.show,
            data: null,
            loading: false,
        }),
        [CLOSE_EPIREQUEST_FORM]: () => ({
            show: false,
            data: null,
            loading: false,
        }),
        [EDIT_SELECTED_EPIREQUEST]: (s, action) => ({
            show: true,
            data: action.payload,
            loading: false,
        }),
        [EPIREQUEST_FORM_TOOGLE_LOADING]: (state) => ({
            ...state,
            loading: !state.loading,
        }),
    },
    { show: false, data: null, loading: false }
);

// ABSENCES
export const absences = handleActions(
    {
        [SET_ABSENCE_LIST]: (state, action) => action.payload,
        [ADD_NEW_ABSENCE]: (state, action) => {
            const newState = {
                ...state,
                [action.payload.value]: action.payload,
            };
            return newState;
        },
        [UPDATE_ABSENCE]: (state, action) => {
            const newState = {
                ...state,
                [action.payload.value]: action.payload,
            };
            return newState;
        },
        [DELETE_ABSENCE]: (state, action) => {
            action.payload.forEach((number) => {
                delete state[number];
            });
            return { ...state };
        },
    },
    null
);

export const absencesForm = handleActions(
    {
        [OPEN_ABSENCE_FORM]: (state) => ({
            show: !state.show,
            data: null,
            loading: false,
        }),
        [CLOSE_ABSENCE_FORM]: () => ({
            show: false,
            data: null,
            loading: false,
        }),
        [EDIT_SELECTED_ABSENCE]: (s, action) => ({
            show: true,
            data: action.payload,
            loading: false,
        }),
        [ABSENCE_FORM_TOOGLE_LOADING]: (state) => ({
            ...state,
            loading: !state.loading,
        }),
    },
    { show: false, data: null, loading: false }
);

// PERMISIONS
export const permisions = handleActions(
    {
        [SET_PERMISION_LIST]: (state, action) => action.payload,
        [ADD_NEW_PERMISION]: (state, action) => {
            const newState = {
                ...state,
                [action.payload.value]: action.payload,
            };
            return newState;
        },
        [UPDATE_PERMISION]: (state, action) => {
            const newState = {
                ...state,
                [action.payload.value]: action.payload,
            };
            return newState;
        },
        [DELETE_PERMISION]: (state, action) => {
            action.payload.forEach((number) => {
                delete state[number];
            });
            return { ...state };
        },
    },
    null
);

export const permisionsForm = handleActions(
    {
        [OPEN_PERMISION_FORM]: (state) => ({
            show: !state.show,
            data: null,
            loading: false,
        }),
        [CLOSE_PERMISION_FORM]: () => ({
            show: false,
            data: null,
            loading: false,
        }),
        [EDIT_SELECTED_PERMISION]: (s, action) => ({
            show: true,
            data: action.payload,
            loading: false,
        }),
        [PERMISION_FORM_TOOGLE_LOADING]: (state) => ({
            ...state,
            loading: !state.loading,
        }),
    },
    { show: false, data: null, loading: false }
);

// SHIFTS
export const shifts = handleActions(
    {
        [SET_SHIFT_LIST]: (state, action) => action.payload,
        [ADD_NEW_SHIFT]: (state, action) => {
            const newState = {
                ...state,
                [action.payload.value]: action.payload,
            };
            return newState;
        },
        [UPDATE_SHIFT]: (state, action) => {
            const newState = {
                ...state,
                [action.payload.value]: action.payload,
            };
            return newState;
        },
        [DELETE_SHIFT]: (state, action) => {
            action.payload.forEach((number) => {
                delete state[number];
            });
            return { ...state };
        },
    },
    null
);

export const shiftsForm = handleActions(
    {
        [OPEN_SHIFT_FORM]: (state) => ({
            show: !state.show,
            data: null,
            loading: false,
        }),
        [CLOSE_SHIFT_FORM]: () => ({
            show: false,
            data: null,
            loading: false,
        }),
        [EDIT_SELECTED_SHIFT]: (s, action) => ({
            show: true,
            data: action.payload,
            loading: false,
        }),
        [SHIFT_FORM_TOOGLE_LOADING]: (state) => ({
            ...state,
            loading: !state.loading,
        }),
    },
    { show: false, data: null, loading: false }
);

// EQUIPMENTS
export const equipments = handleActions(
    {
        [SET_EQUIPMENT_LIST]: (state, action) => action.payload,
        [ADD_NEW_EQUIPMENT]: (state, action) => {
            const newState = {
                ...state,
                [action.payload.value]: action.payload,
            };
            return newState;
        },
        [UPDATE_EQUIPMENT]: (state, action) => {
            const newState = {
                ...state,
                [action.payload.value]: action.payload,
            };
            return newState;
        },
        [DELETE_EQUIPMENT]: (state, action) => {
            action.payload.forEach((number) => {
                delete state[number];
            });
            return { ...state };
        },
    },
    null
);

export const equipmentsForm = handleActions(
    {
        [OPEN_EQUIPMENT_FORM]: (state) => ({
            show: !state.show,
            data: null,
            loading: false,
        }),
        [CLOSE_EQUIPMENT_FORM]: () => ({
            show: false,
            data: null,
            loading: false,
        }),
        [EDIT_SELECTED_EQUIPMENT]: (s, action) => ({
            show: true,
            data: action.payload,
            loading: false,
        }),
        [EQUIPMENT_FORM_TOOGLE_LOADING]: (state) => ({
            ...state,
            loading: !state.loading,
        }),
    },
    { show: false, data: null, loading: false }
);

// SINGUPS
export const singUps = handleActions(
    {
        [SET_SINGUP_LIST]: (state, action) => action.payload,
        [ADD_NEW_SINGUP]: (state, action) => {
            const newState = {
                ...state,
                [action.payload.value]: action.payload,
            };
            return newState;
        },
        [UPDATE_SINGUP]: (state, action) => {
            const newState = {
                ...state,
                [action.payload.value]: action.payload,
            };
            return newState;
        },
        [DELETE_SINGUP]: (state, action) => {
            action.payload.forEach((number) => {
                delete state[number];
            });
            return { ...state };
        },
    },
    null
);

export const singUpsForm = handleActions(
    {
        [OPEN_SINGUP_FORM]: (state) => ({
            show: !state.show,
            data: null,
            loading: false,
        }),
        [CLOSE_SINGUP_FORM]: () => ({
            show: false,
            data: null,
            loading: false,
        }),
        [EDIT_SELECTED_SINGUP]: (s, action) => ({
            show: true,
            data: action.payload,
            loading: false,
        }),
        [SINGUP_FORM_TOOGLE_LOADING]: (state) => ({
            ...state,
            loading: !state.loading,
        }),
    },
    { show: false, data: null, loading: false }
);

// PETITIONS
export const petitions = handleActions(
    {
        [SET_PETITION_LIST]: (state, action) => action.payload,
        [ADD_NEW_PETITION]: (state, action) => {
            const newState = {
                ...state,
                [action.payload.value]: action.payload,
            };
            return newState;
        },
        [UPDATE_PETITION]: (state, action) => {
            const newState = {
                ...state,
                [action.payload.value]: action.payload,
            };
            return newState;
        },
        [DELETE_PETITION]: (state, action) => {
            action.payload.forEach((number) => {
                delete state[number];
            });
            return { ...state };
        },
    },
    null
);

export const petitionsForm = handleActions(
    {
        [OPEN_PETITION_FORM]: (state) => ({
            show: !state.show,
            data: null,
            loading: false,
        }),
        [CLOSE_PETITION_FORM]: () => ({
            show: false,
            data: null,
            loading: false,
        }),
        [EDIT_SELECTED_PETITION]: (s, action) => ({
            show: true,
            data: action.payload,
            loading: false,
        }),
        [PETITION_FORM_TOOGLE_LOADING]: (state) => ({
            ...state,
            loading: !state.loading,
        }),
    },
    { show: false, data: null, loading: false }
);

// HOLIDAYS
export const holidays = handleActions(
    {
        [SET_HOLIDAY_LIST]: (state, action) => action.payload,
        [ADD_NEW_HOLIDAY]: (state, action) => {
            const newState = {
                ...state,
                [action.payload.value]: action.payload,
            };
            return newState;
        },
        [UPDATE_HOLIDAY]: (state, action) => {
            const newState = {
                ...state,
                [action.payload.value]: action.payload,
            };
            return newState;
        },
        [DELETE_HOLIDAY]: (state, action) => {
            action.payload.forEach((number) => {
                delete state[number];
            });
            return { ...state };
        },
    },
    null
);

export const holidaysForm = handleActions(
    {
        [OPEN_HOLIDAY_FORM]: (state) => ({
            show: !state.show,
            data: null,
            loading: false,
        }),
        [CLOSE_HOLIDAY_FORM]: () => ({
            show: false,
            data: null,
            loading: false,
        }),
        [EDIT_SELECTED_HOLIDAY]: (s, action) => ({
            show: true,
            data: action.payload,
            loading: false,
        }),
        [HOLIDAY_FORM_TOOGLE_LOADING]: (state) => ({
            ...state,
            loading: !state.loading,
        }),
    },
    { show: false, data: null, loading: false }
);

// MAINTENANCES
export const maintenances = handleActions(
    {
        [SET_MAINTENANCE_LIST]: (state, action) => action.payload,
        [ADD_NEW_MAINTENANCE]: (state, action) => {
            const newState = {
                ...state,
                [action.payload.value]: action.payload,
            };
            return newState;
        },
        [UPDATE_MAINTENANCE]: (state, action) => {
            const newState = {
                ...state,
                [action.payload.value]: action.payload,
            };
            return newState;
        },
        [DELETE_MAINTENANCE]: (state, action) => {
            action.payload.forEach((number) => {
                delete state[number];
            });
            return { ...state };
        },
    },
    null
);

export const maintenancesForm = handleActions(
    {
        [OPEN_MAINTENANCE_FORM]: (state) => ({
            show: !state.show,
            data: null,
            loading: false,
        }),
        [CLOSE_MAINTENANCE_FORM]: () => ({
            show: false,
            data: null,
            loading: false,
        }),
        [EDIT_SELECTED_MAINTENANCE]: (s, action) => ({
            show: true,
            data: action.payload,
            loading: false,
        }),
        [MAINTENANCE_FORM_TOOGLE_LOADING]: (state) => ({
            ...state,
            loading: !state.loading,
        }),
    },
    { show: false, data: null, loading: false }
);

// WORKORDERS
export const workOrders = handleActions(
    {
        [SET_WORKORDER_LIST]: (state, action) => action.payload,
        [ADD_NEW_WORKORDER]: (state, action) => {
            const newState = {
                ...state,
                [action.payload.value]: action.payload,
            };
            return newState;
        },
        [UPDATE_WORKORDER]: (state, action) => {
            const newState = {
                ...state,
                [action.payload.value]: action.payload,
            };
            return newState;
        },
        [DELETE_WORKORDER]: (state, action) => {
            action.payload.forEach((number) => {
                delete state[number];
            });
            return { ...state };
        },
    },
    null
);

export const workOrdersForm = handleActions(
    {
        [OPEN_WORKORDER_FORM]: (state) => ({
            show: !state.show,
            data: null,
            loading: false,
        }),
        [CLOSE_WORKORDER_FORM]: () => ({
            show: false,
            data: null,
            loading: false,
        }),
        [EDIT_SELECTED_WORKORDER]: (s, action) => ({
            show: true,
            data: action.payload,
            loading: false,
        }),
        [WORKORDER_FORM_TOOGLE_LOADING]: (state) => ({
            ...state,
            loading: !state.loading,
        }),
    },
    { show: false, data: null, loading: false }
);

// EVENTS
export const events = handleActions(
    {
        [SET_EVENT_LIST]: (state, action) => action.payload,
        [ADD_NEW_EVENT]: (state, action) => {
            const newState = {
                ...state,
                [action.payload.value]: action.payload,
            };
            return newState;
        },
        [UPDATE_EVENT]: (state, action) => {
            const newState = {
                ...state,
                [action.payload.value]: action.payload,
            };
            return newState;
        },
        [DELETE_EVENT]: (state, action) => {
            action.payload.forEach((number) => {
                delete state[number];
            });
            return { ...state };
        },
    },
    null
);

export const eventsForm = handleActions(
    {
        [OPEN_EVENT_FORM]: (state) => ({
            show: !state.show,
            data: null,
            loading: false,
        }),
        [CLOSE_EVENT_FORM]: () => ({
            show: false,
            data: null,
            loading: false,
        }),
        [EDIT_SELECTED_EVENT]: (s, action) => ({
            show: true,
            data: action.payload,
            loading: false,
        }),
        [EVENT_FORM_TOOGLE_LOADING]: (state) => ({
            ...state,
            loading: !state.loading,
        }),
    },
    { show: false, data: null, loading: false }
);

// ARTICLES
export const articles = handleActions(
    {
        [SET_ARTICLE_LIST]: (state, action) => action.payload,
        [ADD_NEW_ARTICLE]: (state, action) => {
            const newState = {
                ...state,
                [action.payload.value]: action.payload,
            };
            return newState;
        },
        [UPDATE_ARTICLE]: (state, action) => {
            const newState = {
                ...state,
                [action.payload.value]: action.payload,
            };
            return newState;
        },
        [DELETE_ARTICLE]: (state, action) => {
            action.payload.forEach((number) => {
                delete state[number];
            });
            return { ...state };
        },
    },
    null
);

export const articlesForm = handleActions(
    {
        [OPEN_ARTICLE_FORM]: (state) => ({
            show: !state.show,
            data: null,
            loading: false,
        }),
        [CLOSE_ARTICLE_FORM]: () => ({
            show: false,
            data: null,
            loading: false,
        }),
        [EDIT_SELECTED_ARTICLE]: (s, action) => ({
            show: true,
            data: action.payload,
            loading: false,
        }),
        [ARTICLE_FORM_TOOGLE_LOADING]: (state) => ({
            ...state,
            loading: !state.loading,
        }),
    },
    { show: false, data: null, loading: false }
);

// ORDERS
export const orders = handleActions(
    {
        [SET_ORDER_LIST]: (state, action) => action.payload,
        [ADD_NEW_ORDER]: (state, action) => {
            const newState = {
                ...state,
                [action.payload.value]: action.payload,
            };
            return newState;
        },
        [UPDATE_ORDER]: (state, action) => {
            const newState = {
                ...state,
                [action.payload.value]: action.payload,
            };
            return newState;
        },
        [DELETE_ORDER]: (state, action) => {
            action.payload.forEach((number) => {
                delete state[number];
            });
            return { ...state };
        },
    },
    null
);

export const ordersForm = handleActions(
    {
        [OPEN_ORDER_FORM]: (state) => ({
            show: !state.show,
            data: null,
            loading: false,
        }),
        [CLOSE_ORDER_FORM]: () => ({
            show: false,
            data: null,
            loading: false,
        }),
        [EDIT_SELECTED_ORDER]: (s, action) => ({
            show: true,
            data: action.payload,
            loading: false,
        }),
        [ORDER_FORM_TOOGLE_LOADING]: (state) => ({
            ...state,
            loading: !state.loading,
        }),
    },
    { show: false, data: null, loading: false }
);

// CONFIGURATIONS
export const configurations = handleActions(
    {
        [SET_CONFIGURATION_LIST]: (state, action) => action.payload,
        [ADD_NEW_CONFIGURATION]: (state, action) => {
            const newState = {
                ...state,
                [action.payload.value]: action.payload,
            };
            return newState;
        },
        [UPDATE_CONFIGURATION]: (state, action) => {
            const newState = {
                ...state,
                [action.payload.value]: action.payload,
            };
            return newState;
        },
        [DELETE_CONFIGURATION]: (state, action) => {
            action.payload.forEach((number) => {
                delete state[number];
            });
            return { ...state };
        },
    },
    null
);

export const configurationsForm = handleActions(
    {
        [OPEN_CONFIGURATION_FORM]: (state) => ({
            show: !state.show,
            data: null,
            loading: false,
        }),
        [CLOSE_CONFIGURATION_FORM]: () => ({
            show: false,
            data: null,
            loading: false,
        }),
        [EDIT_SELECTED_CONFIGURATION]: (s, action) => ({
            show: true,
            data: action.payload,
            loading: false,
        }),
        [CONFIGURATION_FORM_TOOGLE_LOADING]: (state) => ({
            ...state,
            loading: !state.loading,
        }),
    },
    { show: false, data: null, loading: false }
);

// DEPARTMENTS
export const departments = handleActions(
    {
        [SET_DEPARTMENT_LIST]: (state, action) => action.payload,
        [ADD_NEW_DEPARTMENT]: (state, action) => {
            const newState = {
                ...state,
                [action.payload.value]: action.payload,
            };
            return newState;
        },
        [UPDATE_DEPARTMENT]: (state, action) => {
            const newState = {
                ...state,
                [action.payload.value]: action.payload,
            };
            return newState;
        },
        [DELETE_DEPARTMENT]: (state, action) => {
            action.payload.forEach((number) => {
                delete state[number];
            });
            return { ...state };
        },
    },
    null
);

export const departmentsForm = handleActions(
    {
        [OPEN_DEPARTMENT_FORM]: (state) => ({
            show: !state.show,
            data: null,
            loading: false,
        }),
        [CLOSE_DEPARTMENT_FORM]: () => ({
            show: false,
            data: null,
            loading: false,
        }),
        [EDIT_SELECTED_DEPARTMENT]: (s, action) => ({
            show: true,
            data: action.payload,
            loading: false,
        }),
        [DEPARTMENT_FORM_TOOGLE_LOADING]: (state) => ({
            ...state,
            loading: !state.loading,
        }),
    },
    { show: false, data: null, loading: false }
);

// REQUESTS
export const requests = handleActions(
    {
        [SET_REQUEST_LIST]: (state, action) => action.payload,
        [ADD_NEW_REQUEST]: (state, action) => {
            const newState = {
                ...state,
                [action.payload.value]: action.payload,
            };
            return newState;
        },
        [UPDATE_REQUEST]: (state, action) => {
            const newState = {
                ...state,
                [action.payload.value]: action.payload,
            };
            return newState;
        },
        [DELETE_REQUEST]: (state, action) => {
            action.payload.forEach((number) => {
                delete state[number];
            });
            return { ...state };
        },
    },
    null
);

export const requestsForm = handleActions(
    {
        [OPEN_REQUEST_FORM]: (state) => ({
            show: !state.show,
            data: null,
            loading: false,
        }),
        [CLOSE_REQUEST_FORM]: () => ({
            show: false,
            data: null,
            loading: false,
        }),
        [EDIT_SELECTED_REQUEST]: (s, action) => ({
            show: true,
            data: action.payload,
            loading: false,
        }),
        [REQUEST_FORM_TOOGLE_LOADING]: (state) => ({
            ...state,
            loading: !state.loading,
        }),
    },
    { show: false, data: null, loading: false }
);

// ADVERTISEMENTS
export const advertisements = handleActions(
    {
        [SET_ADVERTISEMENT_LIST]: (state, action) => action.payload,
        [ADD_NEW_ADVERTISEMENT]: (state, action) => {
            const newState = {
                ...state,
                [action.payload.value]: action.payload,
            };
            return newState;
        },
        [UPDATE_ADVERTISEMENT]: (state, action) => {
            const newState = {
                ...state,
                [action.payload.value]: action.payload,
            };
            return newState;
        },
        [DELETE_ADVERTISEMENT]: (state, action) => {
            action.payload.forEach((number) => {
                delete state[number];
            });
            return { ...state };
        },
    },
    null
);

export const advertisementsForm = handleActions(
    {
        [OPEN_ADVERTISEMENT_FORM]: (state) => ({
            show: !state.show,
            data: null,
            loading: false,
        }),
        [CLOSE_ADVERTISEMENT_FORM]: () => ({
            show: false,
            data: null,
            loading: false,
        }),
        [EDIT_SELECTED_ADVERTISEMENT]: (s, action) => ({
            show: true,
            data: action.payload,
            loading: false,
        }),
        [ADVERTISEMENT_FORM_TOOGLE_LOADING]: (state) => ({
            ...state,
            loading: !state.loading,
        }),
    },
    { show: false, data: null, loading: false }
);

// ROLES
export const roles = handleActions(
    {
        [SET_ROLE_LIST]: (state, action) => action.payload,
        [ADD_NEW_ROLE]: (state, action) => {
            const newState = {
                ...state,
                [action.payload.value]: action.payload,
            };
            return newState;
        },
        [UPDATE_ROLE]: (state, action) => {
            const newState = {
                ...state,
                [action.payload.value]: action.payload,
            };
            return newState;
        },
        [DELETE_ROLE]: (state, action) => {
            action.payload.forEach((number) => {
                delete state[number];
            });
            return { ...state };
        },
    },
    null
);

export const rolesForm = handleActions(
    {
        [OPEN_ROLE_FORM]: (state) => ({
            show: !state.show,
            data: null,
            loading: false,
        }),
        [CLOSE_ROLE_FORM]: () => ({
            show: false,
            data: null,
            loading: false,
        }),
        [EDIT_SELECTED_ROLE]: (s, action) => ({
            show: true,
            data: action.payload,
            loading: false,
        }),
        [ROLE_FORM_TOOGLE_LOADING]: (state) => ({
            ...state,
            loading: !state.loading,
        }),
    },
    { show: false, data: null, loading: false }
);

// TPVS
export const tpvs = handleActions(
    {
        [SET_TPV_LIST]: (state, action) => action.payload,
        [ADD_NEW_TPV]: (state, action) => {
            const newState = {
                ...state,
                [action.payload.value]: action.payload,
            };
            return newState;
        },
        [UPDATE_TPV]: (state, action) => {
            const newState = {
                ...state,
                [action.payload.value]: action.payload,
            };
            return newState;
        },
        [DELETE_TPV]: (state, action) => {
            action.payload.forEach((number) => {
                delete state[number];
            });
            return { ...state };
        },
    },
    null
);

export const tpvsForm = handleActions(
    {
        [OPEN_TPV_FORM]: (state) => ({
            show: !state.show,
            data: null,
            loading: false,
        }),
        [CLOSE_TPV_FORM]: () => ({
            show: false,
            data: null,
            loading: false,
        }),
        [EDIT_SELECTED_TPV]: (s, action) => ({
            show: true,
            data: action.payload,
            loading: false,
        }),
        [TPV_FORM_TOOGLE_LOADING]: (state) => ({
            ...state,
            loading: !state.loading,
        }),
    },
    { show: false, data: null, loading: false }
);

// TPVREQUESTS
export const tpvRequests = handleActions(
    {
        [SET_TPVREQUEST_LIST]: (state, action) => action.payload,
        [ADD_NEW_TPVREQUEST]: (state, action) => {
            const newState = {
                ...state,
                [action.payload.value]: action.payload,
            };
            return newState;
        },
        [UPDATE_TPVREQUEST]: (state, action) => {
            const newState = {
                ...state,
                [action.payload.value]: action.payload,
            };
            return newState;
        },
        [DELETE_TPVREQUEST]: (state, action) => {
            action.payload.forEach((number) => {
                delete state[number];
            });
            return { ...state };
        },
    },
    null
);

export const tpvRequestsForm = handleActions(
    {
        [OPEN_TPVREQUEST_FORM]: (state) => ({
            show: !state.show,
            data: null,
            loading: false,
        }),
        [CLOSE_TPVREQUEST_FORM]: () => ({
            show: false,
            data: null,
            loading: false,
        }),
        [EDIT_SELECTED_TPVREQUEST]: (s, action) => ({
            show: true,
            data: action.payload,
            loading: false,
        }),
        [TPVREQUEST_FORM_TOOGLE_LOADING]: (state) => ({
            ...state,
            loading: !state.loading,
        }),
    },
    { show: false, data: null, loading: false }
);

// CENTERS
export const centers = handleActions(
    {
        [SET_CENTER_LIST]: (state, action) => action.payload,
        [ADD_NEW_CENTER]: (state, action) => {
            const newState = {
                ...state,
                [action.payload.value]: action.payload,
            };
            return newState;
        },
        [UPDATE_CENTER]: (state, action) => {
            const newState = {
                ...state,
                [action.payload.value]: action.payload,
            };
            return newState;
        },
        [DELETE_CENTER]: (state, action) => {
            action.payload.forEach((number) => {
                delete state[number];
            });
            return { ...state };
        },
    },
    null
);

export const centersForm = handleActions(
    {
        [OPEN_CENTER_FORM]: (state) => ({
            show: !state.show,
            data: null,
            loading: false,
        }),
        [CLOSE_CENTER_FORM]: () => ({
            show: false,
            data: null,
            loading: false,
        }),
        [EDIT_SELECTED_CENTER]: (s, action) => ({
            show: true,
            data: action.payload,
            loading: false,
        }),
        [CENTER_FORM_TOOGLE_LOADING]: (state) => ({
            ...state,
            loading: !state.loading,
        }),
    },
    { show: false, data: null, loading: false }
);
const refuelingReducers = createCrudReducers('REFUELING');

export const refuelings = refuelingReducers.list;
export const refuelingsForm = refuelingReducers.form;
