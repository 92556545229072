import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
    ADD_NEW_PERMISION,
    DELETE_PERMISION,
    PERMISION_FORM_TOOGLE_LOADING,
    SET_PERMISION_LIST,
    SHOW_NOTIFICATION,
    UPDATE_PERMISION,
} from "../constants";
import { formatPermisions } from "./settingsActionsUtils";

/* PERMISION LIST */
export const fetchPermisions = () => {
    return async (dispatch) => {
        const response = await axios
            .get(API.permisions.list)
            .then((response) => {
                const permisions = formatPermisions(response.data);
                dispatch({
                    type: SET_PERMISION_LIST,
                    payload: keyBy(permisions, "_id"),
                });
                return response.status;
            })
            .catch((err) => err);
        return response;
    };
};

// PERMISIONS
export const createPermision = (newPermision) => {
    return async (dispatch) => {
        dispatch({ type: PERMISION_FORM_TOOGLE_LOADING });
        const response = await axios
            .post(API.permisions.create, newPermision)
            .then((response) => {
                const permision = formatPermisions(response.data);
                dispatch({ type: ADD_NEW_PERMISION, payload: permision });
                dispatch({ type: PERMISION_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "success",
                        message: "Permiso creado con éxito.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: PERMISION_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const updatePermision = (updatedPermision) => {
    return async (dispatch) => {
        dispatch({ type: PERMISION_FORM_TOOGLE_LOADING });
        const response = await axios
            .put(`${API.permisions.edit}${updatedPermision && updatedPermision._id}`, updatedPermision)
            .then((response) => {
                const permision = formatPermisions(response.data);
                dispatch({ type: UPDATE_PERMISION, payload: permision });
                dispatch({ type: PERMISION_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Permiso actualizado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: PERMISION_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};


export const fetchPermision = (_id) => {
    return async (dispatch) => {
        dispatch({ type: PERMISION_FORM_TOOGLE_LOADING });
        const response = await axios
            .get(`${API.permisions.edit}${_id}`)
            .then((response) => {
                const permision = formatPermisions(response.data);
                dispatch({ type: UPDATE_PERMISION, payload: permision });
                dispatch({ type: PERMISION_FORM_TOOGLE_LOADING });
                return permision;
            })
            .catch((err) => {
                dispatch({ type: PERMISION_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const deletePermisions = (Ids) => {
    return async (dispatch) => {
        dispatch({ type: PERMISION_FORM_TOOGLE_LOADING });
        const response = await axios
            .delete(`${API.permisions.delete}`, { data: Ids })
            .then((response) => {
                dispatch({ type: DELETE_PERMISION, payload: Ids });
                dispatch({ type: PERMISION_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Permiso eliminado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: PERMISION_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};
