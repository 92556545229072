import { HomeOutlined, LoginOutlined, LogoutOutlined, UserOutlined } from '@ant-design/icons';
import React from 'react';

export const routes = {
  home: {
    key: "home",
    slug: "home",
    to: "/home",
    title: "Menú",
    icon: <HomeOutlined />,
    showInMenu: false,
    showHeader: true,
    showSearch: true,
    action: null,
  },

  profile: {
    key: "profile",
    slug: "profile",
    to: "/profile",
    title: "Perfil",
    icon: <UserOutlined />,
    showInMenu: false,
    showHeader: true,
    showSearch: true,
    action: null,
  },

  users: {
    key: "users",
    slug: "users",
    to: "/users",
    title: "Usuarios",
    icon: <UserOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,

  },

  roles: {
    key: "roles",
    slug: "roles",
    to: "/roles",
    title: "Roles",
    icon: <HomeOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,

  },

  login: {
    key: "login",
    slug: "login",
    to: "/login",
    title: "LOGIN",
    icon: <LoginOutlined />,
    showInMenu: false,
    showHeader: false,
    showSearch: false,
    action: null,

  },

  vehicles: {
    key: "vehicles",
    slug: "vehicles",
    to: "/vehicles",
    title: "Vehículos",
    icon: <HomeOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,

  },
  customers: {
    key: "customers",
    slug: "customers",
    to: "/customers",
    title: "Clientes",
    icon: <HomeOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
  },

  centers: {
    key: "centers",
    slug: "centers",
    to: "/centers",
    title: "Centros",
    icon: <HomeOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
  },

  roads: {
    key: "roads",
    slug: "roads",
    to: "/roads",
    title: "Rutas",
    icon: <HomeOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,

  },
  roatSheets: {
    key: "roatSheets",
    slug: "roatSheets",
    to: "/roatSheets",
    title: "Hoja de rutas",
    icon: <HomeOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
  },
  statsRoatSheets: {
    key: "statsRoatSheets",
    slug: "statsRoatSheets",
    to: "/statsRoatSheets",
    title: "Estadísticas de rutas",
    icon: <HomeOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
  },
  expenses: {
    key: "expenses",
    slug: "expenses",
    to: "/expenses",
    title: "Gastos",
    icon: <HomeOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,

  },
  epis: {
    key: "epis",
    slug: "epis",
    to: "/epis",
    title: "Materiales",
    icon: <HomeOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,

  },
  epiRequests: {
    key: "epiRequests",
    slug: "epiRequests",
    to: "/epiRequests",
    title: "Entrega de materiales",
    icon: <HomeOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,

  },
  absences: {
    key: "absences",
    slug: "absences",
    to: "/absences",
    title: "Bajas médicas",
    icon: <HomeOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,

  },
  permisions: {
    key: "permisions",
    slug: "permisions",
    to: "/permisions",
    title: "Permisos",
    icon: <HomeOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,

  },
  shifts: {
    key: "shifts",
    slug: "shifts",
    to: "/shifts",
    title: "Turnos",
    icon: <HomeOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,

  },
  equipments: {
    key: "equipments",
    slug: "equipments",
    to: "/equipments",
    title: "Devoluciones y garantías",
    icon: <HomeOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,

  },
  singUps: {
    key: "singUps",
    slug: "singUps",
    to: "/singUps",
    title: "Fichajes",
    icon: <HomeOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,

  },
  singUp: {
    key: "singUp",
    slug: "singUp",
    to: "/singUp",
    title: "Fichar",
    icon: <HomeOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,

  },
  mapSingUps: {
    key: "mapSingUps",
    slug: "mapSingUps",
    to: "/mapSingUps",
    title: "Mapa de fichajes",
    icon: <HomeOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,

  },
  mySingUps: {
    key: "mySingUps",
    slug: "mySingUps",
    to: "/mySingUps",
    title: "Mis fichajes",
    icon: <HomeOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,

  },
  petitions: {
    key: "petitions",
    slug: "petitions",
    to: "/petitions",
    title: "Peticiones",
    icon: <HomeOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,

  },
  holidays: {
    key: "holidays",
    slug: "holidays",
    to: "/holidays",
    title: "Vacaciones",
    icon: <HomeOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,

  },
  maintenances: {
    key: "maintenances",
    slug: "maintenances",
    to: "/maintenances",
    title: "Mantenimientos",
    icon: <HomeOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,

  },
  workOrders: {
    key: "workOrders",
    slug: "workOrders",
    to: "/workOrders",
    title: "Partes de trabajos",
    icon: <HomeOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,

  },
  events: {
    key: "events",
    slug: "events",
    to: "/events",
    title: "Eventos",
    icon: <HomeOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,

  },
  articles: {
    key: "articles",
    slug: "articles",
    to: "/articles",
    title: "Artículos",
    icon: <HomeOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,

  },
  orders: {
    key: "orders",
    slug: "orders",
    to: "/orders",
    title: "Pedidos",
    icon: <HomeOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,

  },
  customersMaps: {
    key: "customersMaps",
    slug: "customersMaps",
    to: "/customersMaps",
    title: "Mapa de comerciales",
    icon: <HomeOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,

  },
  configurations: {
    key: "configurations",
    slug: "configurations",
    to: "/configurations",
    title: "Configuración",
    icon: <HomeOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,

  },
  departments: {
    key: "departments",
    slug: "departments",
    to: "/departments",
    title: "Departamentos",
    icon: <HomeOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,

  },
  requests: {
    key: "requests",
    slug: "requests",
    to: "/requests",
    title: "Solicitud de material",
    icon: <HomeOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,

  },
  advertisements: {
    key: "advertisements",
    slug: "advertisements",
    to: "/advertisements",
    title: "Anuncios",
    icon: <HomeOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,

  },
  bulletinBoard: {
    key: "bulletinBoard",
    slug: "bulletinBoard",
    to: "/bulletinBoard",
    title: "Tablón de anuncios",
    icon: <HomeOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,

  },
  tpvs: {
    key: "tpvs",
    slug: "tpvs",
    to: "/tpvs",
    title: "TPVs",
    icon: <HomeOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
  },
  tpvRequests: {
    key: "tpvRequests",
    slug: "tpvRequests",
    to: "/tpvRequests",
    title: "Recogida TPVs",
    icon: <HomeOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
  },

  refuelings: {
    key: "refuelings",
    slug: "refuelings",
    to: "/refuelings",
    title: "Repostajes",
    icon: <HomeOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
  },

  logout: {
    key: "logout",
    slug: "logout",
    to: "/logout",
    title: "Cerrar Sesión",
    icon: <LogoutOutlined />,
    showInMenu: false,
    showHeader: false,
    showSearch: false,
    action: null,

  },
};